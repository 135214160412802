import { useEffect } from "react";
import "../../../node_modules/wowjs/css/libs/animate.css";
import WOW from "wowjs";
import { scroller } from "react-scroll";

import FaqBlock from "./Elements/FaqBlock";
import Project from "./Elements/Project";
import Lights from "../Light/Lights";
import Preview from "../Preview/Preview";
import Text from "./Elements/Text";

import "./Content.css";

function Content() {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div className="content">
      <Preview />
      <div className="company_info block wow fadeInUp" data-wow-delay="0.2s" >
        <div className="photo">
          <img src={require("../../img/Frame 36135802.png")} alt="Photo" />
        </div>
        <div className="info">
          <div className="">
            <p className="title">itCredo.</p>
          </div>
          <p className="experience_title">
            Специалисты по информационным технологиям предоставляющие широкий спектр услуг для бизнеса, таких как разработка программного обеспечения, создание мобильных приложений, систем и использование искусственного интеллекта.
          </p>
          <div className="experience">
            <Text
              big={"30+"}
              size_b={"35px"}
              small={"реализованных проектов"}
              size_s={"16px"}
            />
            <Text
              big={"5+"}
              size_b={"35px"}
              small={"лет опыта команды"}
              size_s={"16px"}
            />
          </div>
          <div className="ceo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
            >
              <circle
                cx="21.9998"
                cy="11.0003"
                r="7.33333"
                stroke="white"
                stroke-width="1.5"
              />
              <ellipse
                opacity="0.5"
                cx="21.9998"
                cy="31.1663"
                rx="12.8333"
                ry="7.33333"
                stroke="white"
                stroke-width="1.5"
              />
            </svg>
            <Text
              big={"Дмитрий Казмирчук"}
              size_b={"18px"}
              fontWeight_b={"700"}
              small={"Руководитель компании и CEO"}
              size_s={"16px"}
            />
          </div>
        </div>
      </div>
      <div id="services" className="services block wow fadeInUp" data-wow-delay="0.2s">
        <p className="title start">Услуги</p>
        <p className="title_text start">
          создайте инновации с нашими возможностями
        </p>
        <div className="cards">
          <div className="card">
            <img src={require("../../img/services/service_1.png")} alt="img" />
            <div className="card_info">
              <Text
                big={"Разработка ПО"}
                size_b={"24px"}
                fontWeight_b={700}
                gap={"10px"}
                small={
                  "Индивидуальная разработка ПО, чтобы вывести ваш бизнес на новый уровень. Мы предлагаем полный спектр услуг: от концепции до кода и поддержки."
                }
                size_s={"16px"}
              />
            </div>
          </div>
          <div className="card">
            <img src={require("../../img/services/service_2.png")} alt="img" />
            <div className="card_info">
              <Text
                big={"Приложения"}
                size_b={"24px"}
                fontWeight_b={700}
                gap={"10px"}
                small={
                  "Ваши идеи заслуживают лучшего воплощения. Мы разрабатываем мобильные и веб-приложения, которые привлекают пользователей и продвигают ваш бренд."
                }
                size_s={"16px"}
              />
            </div>
          </div>
          <div className="card">
            <img src={require("../../img/services/service_3.png")} alt="img" />
            <div className="card_info">
              <Text
                big={"Digital-системы"}
                size_b={"24px"}
                fontWeight_b={700}
                gap={"10px"}
                small={
                  "Интегрируйте ваш бизнес с современными цифровыми системами. Автоматизация, аналитика и многое другое для увеличения эффективности и оптимизации управления."
                }
                size_s={"16px"}
              />
            </div>
          </div>
          <div className="card">
            <img src={require("../../img/services/service_4.png")} alt="img" />
            <div className="card_info">
              <Text
                big={"Нейронные сети"}
                size_b={"24px"}
                fontWeight_b={700}
                gap={"10px"}
                small={
                  "Революционные нейронные сети для решения сложнейших задач. Искусственный интеллект, который помогает вашему бизнесу оставаться на переднем крае инноваций."
                }
                size_s={"16px"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="our_system block wow fadeInUp" data-wow-delay="0.2s" >
        <div className="tag start">Внедряем</div>
        <p className="title start">CI/CD — ваш ключ к быстрой и эффективной разработке!</p>
        <div className="systems">
          <div className="card">
            <span>Внедрение</span>
            <ul>
              <li>Анализ и выбор инструментов и технологий</li>
              <li>Проектирование и разработка</li>
              <li>Внедрение и настройка</li>
              <li>Тестирование и оптимизация</li>
              <li>Интеграция с текущими процессами</li>
              <li>Мониторинг и анализ</li>
            </ul>
          </div>
          <div className="card">
            <span>Приемущества</span>
            <ul>
              <li>Быстрое развёртывание новых функций</li>
              <li>Улучшение качества кода</li>
              <li>Повышение надёжности продукта</li>
              <li>Снижение рисков</li>
            </ul>
            <button className="white_button" onClick={() => {
              scroller.scrollTo("feedback", {
                offset: -100,
                smooth: true,
              })
            }}>Заказать</button>
            
          </div>
        </div>
        <div className="staps_list">
          <div className="stap">План</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Код</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Сборка</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Тест</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Релиз</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Разверывание</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Поддержка</div> <img src={require("../../img/svg/arrow.svg").default} alt="" />
          <div className="stap">Мониторинг</div>
        </div>
      </div>
      <div className="automatisation block wow fadeInUp" data-wow-delay="0.2s" >
        <div className="title">Автоматизация рабочих процессов и Искусственный интеллект для бизнеса</div>
        <div className="title_text start">Создание ИИ для вашего бизнеса, это вложение в будущее и развитие. </div>
        <div className="list">
          <div className="card">
            <img src={require("../../img/automatisation/marketing.png")} alt="" />
            <Text big={"Маркетинг"} size_b={"24px"} fontWeight_b={700} small={"персонализация рекламы, анализ поведения клиентов, прогнозирование спроса."} size_s={"16px"} />
          </div>
          <div className="card">
            <img src={require("../../img/automatisation/sales.png")} alt="" />
            <Text big={"Продажи"} size_b={"24px"} fontWeight_b={700} small={"прогнозирование продаж, автоматизация обработки заказов, оптимизация ценообразования."} size_s={"16px"} />
          </div>
          <div className="card">
            <img src={require("../../img/automatisation/production.png")} alt="" />
            <Text big={"Производство"} size_b={"24px"} fontWeight_b={700} small={"оптимизация производственных процессов, контроль качества, прогнозирование поломок."} size_s={"16px"} />
          </div>
          <div className="card">
            <img src={require("../../img/automatisation/logistics.png")} alt="" />
            <Text big={"Логистика"} size_b={"24px"} fontWeight_b={700} small={"оптимизация маршрутов доставки, прогнозирование спроса на товары, управление запасами."} size_s={"16px"} />
          </div>
          <div className="card">
            <img src={require("../../img/automatisation/finance.png")} alt="" />
            <Text big={"Финансы"} size_b={"24px"} fontWeight_b={700} small={"анализ финансовых данных, прогнозирование рисков, автоматизация бухгалтерского учёта."} size_s={"16px"} />
          </div>
          <div className="card">
            <Text big={"Индивидальные комерческие задачи с ИИ"} size_b={"24px"} fontWeight_b={700} />
            <button className="white_button" onClick={() => {
              scroller.scrollTo("feedback", {
                offset: -100,
                smooth: true,
              })
            }}>Связаться</button>
          </div>
        </div>
        
        <div className="ai">
          
        </div>
      </div>
      <div className="stack block">
        <div className="left">
            <div className="tag">Наш стэк</div>
            <p className="title">Мы работаем с...</p>
            <p className="title_text">Выбор стека технологий для разработки зависит от требований проекта, предпочтений разработчиков и других факторов. Вот некоторые популярные технологии и инструменты, которые мы можем использованы в стеке разработки:</p>
            <button className="white_button" onClick={() => {
              scroller.scrollTo("feedback", {
                offset: -100,
                smooth: true,
              })
            }}>Заказать проект</button>
        </div>
        <div className="right">
          <div className="left_collumn">
            <div className="card">
              <img src={require("../../img/svg/reactjs.svg").default} alt="" />
              <Text big={"React.JS"} size_b={"16px"} fontWeight_b={600} />
            </div>
            <div className="card">
              <img src={require("../../img/svg/nodejs.svg").default} alt="" />
              <Text big={"Node.JS"} size_b={"16px"} fontWeight_b={600} />
            </div>
            <div className="card">
              <img src={require("../../img/svg/swift.svg").default} alt="" />
              <Text big={"Swift"} size_b={"16px"} fontWeight_b={600} />
            </div>
          </div>
          <div className="right_collumn">
            <div className="card">
              <img src={require("../../img/svg/kotlin.svg").default} alt="" />
              <Text big={"Kotlin"} size_b={"16px"} fontWeight_b={600} />
            </div>
            <div className="card">
              <img src={require("../../img/svg/nextjs.svg").default} alt="" />
              <Text big={"Next.JS"} size_b={"16px"} fontWeight_b={600} />
            </div>
            <div className="card">
              <img src={require("../../img/svg/vuejs.svg").default} alt="" />
              <Text big={"Vue.JS"} size_b={"16px"} fontWeight_b={600} />
            </div>
            <div className="card">
              <img src={require("../../img/svg/git.svg").default} alt="" />
              <Text big={"Git"} size_b={"16px"} fontWeight_b={600} />
            </div>
          </div>
        </div>
      </div>
      <div className="work_stages block wow fadeInUp" data-wow-delay="0.2s">
        <p className="title">Этапы работы</p>
        <p className="title_text">
          все процессы при реализации проекта прозрачны и управляемы
        </p>
        <div className="stages">
          <div className="stage">
            <img src={require("../../img/svg/firstStage.svg").default} alt="" />
            <div className="stage_info">
              <Text
                big={"1.Знакомство"}
                size_b={"18px"}
                fontWeight_b={700}
                small={"знакомство с заказчиком"}
                size_s={"16px"}
              />
            </div>
          </div>

          <div className="stage">
            <img src={require("../../img/svg/secondStage.svg").default} alt="" />
            <div className="stage_info">
              <Text
                big={"2.Договор"}
                size_b={"18px"}
                small={"легкий и прозрачный"}
                size_s={"16px"}
              />
            </div>
          </div>
          <div className="stage">
            <img src={require("../../img/svg/thirdStage.svg").default} alt="" />
            <div className="stage_info">
              <Text
                big={"3.Разработка"}
                size_b={"18px"}
                small={"от сбора тз до тестирования"}
                size_s={"16px"}
              />
            </div>
          </div>

          <div className="stage">
            <img src={require("../../img/svg/fourthStage.svg").default} alt="" />
            <div className="stage_info">
              <Text
                big={"4.Релиз"}
                size_b={"18px"}
                small={"пьем шампанское"}
                size_s={"16px"}
              />
            </div>
          </div>

          <div className="stage">
            <img src={require("../../img/svg/fifthStage.svg").default} alt="" />
            <div className="stage_info">
              <Text
                big={"5.Сопровождение"}
                size_b={"18px"}
                small={"помощь на любом этапе"}
                size_s={"16px"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio block wow fadeInUp" data-wow-delay="0.2s">
        <p className="title">Портфолио</p>
        <p className="title_text">это наши работы. </p>
        <p className="title_text">Мы любим когда вы их видите, что бы узнать о нас получше!</p>
        <div className="portfolio_projects swiper-container">
          <div className="swiper-wrapper">
            <Project
              img={require("../../img/Projects/UFEX.png")}
              name="UFEx"
              link="https://ufex.pro/"
              link_name="ufex.pro"
              link_text="Сервис финансовых услуг"
            />
            <Project
              img={require("../../img/Projects/DropShop.png")}
              name="DropShop"
              link="http://dropshop.games/"
              link_name="dropshop.games"
              link_text="Marketplace игровых ценностей"
            />
            <Project
              img={require("../../img/Projects/FinMarket.png")}
              name="FinMarket.pro"
              link=""
              link_name="finmarket.pro"
              link_text="Аукцион финансовых услуг"
            />
            <Project
              img={require("../../img/Projects/LeKover.png")}
              name=""
              link="https://lekover.ru/"
              link_name="lekover.ru"
              link_text="Ковры ручной работы"
            />
            <Project
              img={require("../../img/Projects/Потеряшка.png")}
              name=""
              link="https://poteryashka.com/"
              link_name="poteryashka.com"
              link_text="Поиск пропавших вещей, животных."
            />
            
            <Project
              img={require("../../img/Projects/UFEX.png")}
              name="UFEx"
              link="https://ufex.pro/"
              link_name="ufex.pro"
              link_text="Сервис финансовых услуг"
            />
            <Project
              img={require("../../img/Projects/DropShop.png")}
              name="DropShop"
              link="http://dropshop.games/"
              link_name="dropshop.games"
              link_text="Marketplace игровых ценностей"
            />
            <Project
              img={require("../../img/Projects/FinMarket.png")}
              name="FinMarket.pro"
              link=""
              link_name="finmarket.pro"
              link_text="Аукцион финансовых услуг"
            />
            <Project
              img={require("../../img/Projects/LeKover.png")}
              name=""
              link="https://lekover.ru/"
              link_name="lekover.ru"
              link_text="Ковры ручной работы"
            />
            <Project
              img={require("../../img/Projects/Потеряшка.png")}
              name=""
              link="https://poteryashka.com/"
              link_name="poteryashka.com"
              link_text="Поиск пропавших вещей, животных."
            />
          </div>
        </div>
      </div>
      <div className="team block wow fadeInUp" data-wow-delay="0.2s">
        <p className="title">Наша команда</p>
        <p className="title_text">опытные, креативные и энергичные + iT кот 😸</p>
        <div className="team_people">
          <div className="human">
            <div className="avatar">
              <div className="fortest"></div>
              <img id="fortest" src={require("../../img/avatars/ceo.png")} alt="avatar" />
            </div>
            <Text
              big={"Dmitry Kazmirchuk"}
              size_b={"20px"}
              gap={"5px"}
              small={"CEO"}
              size_s={"18px"}
            />
          </div>
          <div className="human">
            <div className="avatar">
              <img src={require("../../img/avatars/tech_lead.png")} alt="avatar" />
            </div>
            <Text
              big={"Aleksey Khlopitskiy"}
              size_b={"20px"}
              gap={"5px"}
              small={"Tech lead"}
              size_s={"18px"}
            />
          </div>
          <div className="human">
            <div className="avatar">
              <img src={require("../../img/avatars/digital_designer.png")} alt="avatar" />
            </div>
            <Text
              big={"Aleksandr Evenko"}
              size_b={"20px"}
              gap={"5px"}
              small={"Digital Designer"}
              size_s={"18px"}
            />
          </div>
          <div className="human">
            <div className="avatar">
              <img src={require("../../img/avatars/frontend_developer.png")} alt="avatar" />
            </div>
            <Text
              big={"Pasha Tarasov"}
              size_b={"20px"}
              gap={"5px"}
              small={"Backend Developer"}
              size_s={"18px"}
            />
          </div>
          <div className="human">
            <div className="avatar">
              <img src={require("../../img/avatars/backend_developer.png")} alt="avatar" />
            </div>
            <Text
              big={"Egor Gurin"}
              size_b={"20px"}
              gap={"5px"}
              small={"Frontend Developer"}
              size_s={"18px"}
            />
          </div>
        </div>
      </div>
      <div className="feedback block wow fadeInUp" data-wow-delay="0.2s">
        <img src={require("../../img/65112552 1.png")} alt="" id="feedback_img1" />
        <p className="title">Обратная связь</p>
        <p className="title_text ">
          Оставьте контакты и наш менеджер свяжется с вами.
        </p>
        <div className="inputs start">
          <form id="contactForm" 
                onSubmit={(e) => {
                      e.preventDefault();

                      const name = document.getElementById("input_name").value;
                      const email = document.getElementById("input_email").value;
                      const phone = document.getElementById("input_phone").value;

                      if (!name || !email || !phone) {
                        return alert('Не все поля заполнены!');
                      }

                      const formData = {
                        name: name,
                        email: email,
                        phone: phone,
                      };

                      fetch("/submit_form", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(formData),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          if (data.success) {
                            alert("Форма успешно отправлена!");
                          } else {
                            alert("Произошла ошибка при отправке формы.");
                          }
                        })
                        .catch((error) => console.error("Ошибка:", error));
                    }}>
            <input type="text" name="name" id="input_name" placeholder="Имя" required />
            <input type="email" name="email" id="input_email" placeholder="Email" required />
            <input type="text" name="phone" id="input_phone" placeholder="Телефон" required />
            <button className="white_button" type="submit"> Отправить </button>
          </form>
        </div>
        <img src={require("../../img/65112552 2.png")} alt="" id="feedback_img2" />
      </div>
      <div className="faq block wow fadeInUp" data-wow-delay="0.2s">
        <p className="title start">FAQ</p>
        <p className="title_text start">часто задаваемые вопросы</p>
        <div className="quastions">
          <FaqBlock
            quastion={
              "Что включает в себя ваша услуга по разработке программного обеспечения?"
            }
            answer={
              "Услуга по разработке программного обеспечения охватывает весь цикл: от концептуализации, планирования,  проектирования, кодирования, тестирования до запуска и поддержки проекта. Мы используем современные технологии и методологии разработки, чтобы обеспечить эффективность и надежность наших решений."
            }
          />
          <FaqBlock
            quastion={"Каковы этапы разработки приложений нейронных сетей?"}
            answer={
              "Определение требований, определение необходимых данных, сбор данных, обработка данных, разработка модели, итоговое тестирование и принятие решения о внедрении, внедрение, комплексное тестирование и оценка результатов."
            }
          />
          <FaqBlock
            quastion={"Какие этапы разработки WEB сайтов?"}
            answer={
              "Для реализации web сайта необходимо пройти ряд этапов.\nСбор требований от заказчика, проектирование, написание документов по проекту, реализация, тестирование, запуск и сопровождение проекта."
            }
          />
          <FaqBlock
            quastion={"Разработайте с нами ваше приложение на iOS или Android"}
            answer={
              "Этапы реализации приложения: Определение потребностей пользователя. Анализ требований. Устанавливаем цель проекта. Проектирование дизайна. Составление технического задания. Проектирование архитектуры. Разработка. Тестирование и отладка. Установка приложения. Запуск. Обновление приложения приложения при необходимости."
            }
          />
          <FaqBlock
            quastion={"Информационная безопасность"}
            answer={
              "Специалисты компании itCredo. в сфере информационной безопасности имеют в арсенале набор инструментов, которые обеспечивают всестороннюю защиту конфиденциальной корпоративной информации от неправильного использования, несанкционированного доступа, искажения или уничтожения."
            }
          />
        </div>
      </div>

      <Lights />
    </div>
  );
}

export default Content;
